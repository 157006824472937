@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
  color: #cacdce;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* google map custom css */

a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

/*
.gm-style:first-of-type > div:nth-child(1) {
  cursor: none !important;
}
*/

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

/*
.gmnoprint div {
  background:none !important;
}
*/

.pac-container {
  z-index: 9999;
}

.rows {
  padding-left: 20px;
}

.rows .row {
  display: inline-block;
}

.imageList {
  background: white;
  border-radius: 3px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.imageItem {
  background-color: transparent;
}

.imageItem:hover {
  cursor: pointer;
  background-color: rgba(49, 49, 49, 0.397);
}

.image:hover {
  -webkit-filter: brightness(50%);
}

.comment {
  color: aliceblue;
  padding-right: 10px;
}

.inputText {
  padding-right: -40px;
}

.list-container {
  border-radius: 10px;
}

.scroller {
  margin: 0 auto;
  overflow: auto;
}

/* card comment*/

.comment-card {
  background: #f5f5f5;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  box-shadow: 0 0 2px 1px #d0d0d0;
  padding: 5px 10px 7px 10px;
  width: 100%;
}

/* card payment item*/

.item-payment {
  background: #f8f7f7;
  border-radius: 15px;
  box-shadow: 0 0 0 1px #d0d0d0;
  padding: 10px 20px 9px 25px;
  width: 100%;
}

/* notify subcribe*/

.notify-card {
  display: flex;
  background-color: rgb(252, 239, 233);
  border-radius: 10px;
  padding: 12px 15px 12px 15px;
  align-items: center;
}

.notify-card-cancel {
  display: grid;
  background-color: rgba(238, 49, 49, 0.2);
  border-radius: 10px;
  padding: 12px 15px 12px 15px;
  align-items: center;
}

.notify-card-error {
  display: flex;
  background-color: rgba(62, 117, 238, 0.2);
  border-radius: 10px;
  padding: 12px 15px 12px 15px;
  align-items: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s; */
  border: "none";
  /* -webkit-text-fill-color: 'green'; */
  -webkit-box-shadow: "none";
  transition: background-color 5000s ease-in-out 0s;
}

.drag-area {
  border: 2px dashed #fff;
  height: 500px;
  width: 700px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.edit-tooltip .mapboxgl-popup {
  font-size: xx-small;
  color: whitesmoke;
}

/*
.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
  font-size: 4px !important;
  border-radius: 50% !important;
  height: 15px;
  width: 15px;
}
*/

.edit-tooltip .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  border-top-color: #32303094 !important;
}

.edit-tooltip .mapboxgl-popup-content {
  background: #32303094 !important;
  box-shadow: 0 1px 2px rgb(0 0 0 / 61%) !important;
  padding: 5px !important;
}

.chart-area {
  height: 320px;
  width: 100%;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: #404040;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.productbox {
  display: flex;
  margin: auto;
  height: 240px;
  width: 240px;
  background-color: grey;
}

.notdisplayed {
  display: none;
}

.displayed {
  display: flex;
}

/*
 * Hide high-res map rendering
 */
 .hidden-map {
  overflow: hidden !important;
  height: 0 !important;
  width: 0 !important;
  position: fixed !important;
}
.swal2-loader  {
  border-color: #FF844B transparent #FF844B transparent !important;
}

.swal2-title {
  font-size: 1.5rem !important;
  font-family: 'Titillium Web', sans-serif;
}

.react-loading {
  z-index: 4000;
  display: block;
  position: fixed;
  top: 20%;
  left: 30%;
}

.swal2-styled.swal2-confirm {
  background-color: #FF844B !important;
  font-family: 'Titillium Web', sans-serif;
  width: 6rem;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(255, 109, 1, 1) !important;
}

.swal2-html-container {
  font-family: 'Titillium Web', sans-serif;
}

.swal2-container {
  z-index: 10000 !important;
}

.MuiCircularProgress-circleIndeterminate {
  color: rgba(255, 109, 1, 1) !important;
}




